import {createStore} from 'vuex'
import creatPersistedstate from "vuex-persistedstate";

export default createStore({
    state: {
        account: "",
        price: 0,
        productId: 0,
        productList: [],
        shoppingCart: [],
    },
    getters: {},
    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
        setPrice(state, price) {
            state.price = price
        },

        setProductId(state, productId) {
            state.productId = productId
        },
        setProductList(state, productList) {
            state.productList = productList;
        },
        setShoppingCart(state, productList) {
            state.shoppingCart = productList;
        }
    },
    actions: {},
    modules: {},
    plugins: [
        creatPersistedstate({key: "h5_pay"})
    ]
})
