<template>
    <router-view/>
</template>

<script>

import {useStore} from "vuex";

export default {
    name: "App",

    created() {
        this.saveProduct();
    },
    setup() {
        let productList = [
            {
                id: 1,
                name: "基础版",
                desc: "满足基本需求",
                price: "2999",
                // 10个活跃用户
                tenUser: 1,
                // 30个项目集成
                thirtyProject: 1,
                // 数据分析
                dataAnalysis: 0,
                // 优先技术支持
                technicalSupport: 0,
                unlimitedProject: 0
            },
            {
                id: 2,
                name: "专业版",
                desc: "最佳商业配置",
                price: "8999",
                // 10个活跃用户
                tenUser: 1,
                // 30个项目集成
                thirtyProject: 1,
                // 数据分析
                dataAnalysis: 1,
                // 优先技术支持
                technicalSupport: 1,
                unlimitedProject: 0
            },
            {
                id: 1,
                name: "企业版",
                desc: "最佳商业配置",
                price: "15999",
                // 10个活跃用户
                tenUser: 1,
                // 30个项目集成
                thirtyProject: 1,
                // 数据分析
                dataAnalysis: 1,
                // 优先技术支持
                technicalSupport: 1,
                unlimitedProject: 1
            }
        ];

        function saveProduct() {
            let store = useStore();
            store.commit("setProductList", productList);
        }

        return {
            saveProduct
        }

    }
}
</script>

<style lang="less" scoped>


</style>